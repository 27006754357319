import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import OBSWebSocket from 'obs-websocket-js';

import SocketListener from 'components/SocketListener';
import GlobalContext from 'contexts';
import GlobalStore from 'store';
import { getTime } from 'utils';

const store = new GlobalStore();
const socket = new OBSWebSocket();

const SendCurrent = data => {
  // Update the store's current game information based on the incoming WebSocket data
  store.currentGame.title = data.title || 'Default Title';
  store.currentGame.subtitle = data.subtitle || 'Game Subtitle';
  store.currentGame.category = data.category || 'Default Category';
  store.currentGame.platform = data.platform || 'Default Platform';
  store.currentGame.extra = data.extrainfo || '';
  store.currentGame.estimate = data.estimate || '00:00:00';
}

const SendNext = data => {
  // Update the store's next game information based on the incoming WebSocket data
  store.nextGame[0].title = data.title || 'Default Title';
  store.nextGame[0].subtitle = data.subtitle || 'Game Subtitle';
  store.nextGame[0].category = data.category || 'Default Category';
  store.nextGame[0].platform = data.platform || 'Default Platform';
  store.nextGame[0].extra = data.extrainfo || '';
  store.nextGame[0].estimate = data.estimate || '00:00:00';
}

const TimerStart = data => {
  store.startTimer;
}

const TimerEnd = data => {
  store.endTimer;
}

const TimerContinue = data => {
  store.continueTimer;
}

const PlayerContinue = data => {
  store.runners[data.idx].endTime = null;
}

const PlayerStop = async data => {
  store.runners[data.idx].endTime = await getTime();
}

React.useLayoutEffect = React.useEffect;

const GlobalContextProvider = ({ children }) => {
  const router = useRouter();

  if (typeof window === 'undefined') {
    return null;
  }

  store.obs.socket = socket;

  useEffect(() => {
    const obs = store.obs.socket;

    if (!obs) {
      return;
    }

    const refreshData = () => {
      // Scenes
      obs.send('GetSceneList').then(data => {
        store.obs.scenes = data.scenes.map(s => s.name).filter(s => !s.includes('!'));
        store.obs.currentScene = data.currentScene;
      });

      //Sources
      obs.send('GetSourcesList').then(data => {
        // console.log('Sources: ', toJS(data));
        store.obs.groupa = data.sources.map(s => s.name).filter(s => s.includes('Room A'));
        store.obs.groupb = data.sources.map(s => s.name).filter(s => s.includes('Room B'));

        store.obs.hosta = data.sources.map(s => s.name).filter(s => s.includes('Ninja A - Host'));
        store.obs.hostb = data.sources.map(s => s.name).filter(s => s.includes('Ninja B - Host'));

        store.obs.ads = data.sources
          .map(s => s.name)
          .filter(s => s.includes('AD :'))
          .reverse();
      });

      // Preview Scene
      obs.send('GetPreviewScene').then(data => {
        store.obs.previewScene = data.name;
      });

      // Transitions
      obs.send('GetTransitionList').then(data => {
        store.obs.transitions = data.transitions.map(t => t.name);
        store.obs.currentTransition = data.currentTransition;
      });

      for (let i = 0; i < store.obs.audio.length; i++) {
        obs.send('GetMute', { source: store.obs.audio[i].name }).then(data => {
          store.obs.audio[i].muted = data.muted;
        });
      }

      for (let j = 0; j < store.obs.rtmp.length; j++) {
        obs.send('GetMute', { source: store.obs.rtmp[j].name }).then(data => {
          store.obs.rtmp[j].muted = data.muted;
        });
      }

      for (let k = 0; k < store.obs.cam.length; k++) {
        obs.send('GetSceneItemProperties', { item: store.obs.cam[k].name }).then(data => {
          store.obs.cam[k].visible = data.visible;
        });
      }

      if (store.obs.ads !== undefined) {
        for (let l = 0; l < store.obs.ads.length; l++) {
          obs.send('GetSceneItemProperties', { item: store.obs.ads[l] }).then(data => {
            // console.log(data);
            store.obs.ad[l].visible = data.visible;
          });
        }
      }
    };

    // Hydrate the store
    obs.on('ConnectionOpened', () => {
      obs.send('GetStudioModeStatus').then(data => {
        if (!data.studioMode) {
          obs.send('EnableStudioMode');
        }
      });
      refreshData();
    });

    obs.on('ConnectionClosed', () => {
      store.test.connected = false;
    });

    obs.on('SwitchScenes', data => {
      store.obs.currentScene = data.sceneName;
      refreshData();
      obs.send('GetCurrentScene').then(data => {
        store.obs.currentScene = data.name;
      });
    });

    // obs.on('MediaStarted', data => {
    //   console.log('Ad Started ...');
    //   if (data.sourceName.includes('AD')) {
    //     store.setAdIndex(null);
    //   }
    // });

    // obs.on('MediaEnded', data => {
    //   console.log('Ad Ended ...');
    //   //obs.send('SetCurrentTransition', { 'transition-name': 'Fade' });
    //   if (data.sourceName.includes('AD')) {
    //     let name = 'Fade';
    //     obs.send('TransitionToProgram', { 'with-transition': { name } });
    //   }
    // });

    obs.on('PreviewSceneChanged', data => {
      store.obs.previewScene = data.sceneName;
    });

    obs.on('SceneCollectionChanged', data => {
      refreshData();
    });

    obs.on('BroadcastCustomEvent', (data) => {
      switch (data.eventType) {
        case "UCISendCurrent":
          SendCurrent(data);
          return { message: "Successfully updated current game", status: 200 }
        case "UCISendNext":
          SendNext(data);
          return { message: "Successfully updated current game", status: 200 }
        case "UCITimerStart":
          TimerStart(data);
          return { message: "Successfully updated current game", status: 200 }
        case "UCITimerEnd":
          TimerEnd(data);
          return { message: "Successfully updated current game", status: 200 }
        case "UCITimerContinue":
          TimerContinue(data);
          return { message: "Successfully updated current game", status: 200 }
        case "UCIPlayerContinue":
          PlayerContinue(data);
          return { message: "Successfully updated current game", status: 200 }
        case "UCIPlayerStop":
          PlayerStop(data);
          return { message: "Successfully updated current game", status: 200 }
        default:
          return { message: "Command not found.", status: 404 }
      }
    });

  }, [store.obs.socket]);

  return (
    <GlobalContext.Provider value={store}>
      <SocketListener>{children}</SocketListener>
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(GlobalContextProvider);
