import axios from 'axios';

export const pad = i => i.toString().padStart(2, '0');

export const timeDiff = (start, end) => {
  const timestamp = Math.floor((end - start) / 1000);

  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = Math.floor(timestamp % 60);

  return {
    hours,
    minutes,
    seconds,
    formatted: `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`,
    formattedMin: `${pad(minutes)}:${pad(seconds)}`,
  };
};

export const convertTime = seconds => {
  const hr = Math.floor(seconds / 60 / 60);
  const min = Math.floor(seconds / 60) - hr * 60;
  const sec = Math.floor(seconds % 60);

  return `${pad(hr)}:${pad(min)}:${pad(sec)}`;
};

export const getDateString = time => {
  var date = new Date(time);
  return date.toLocaleTimeString();
};

export const chunk = (arr, size) =>
  arr.reduce((acc, val, idx) => {
    const chunkIndex = Math.floor(idx / size);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = []; // start a new chunk
    }

    acc[chunkIndex].push(val);

    return acc;
  }, []);

export const getTime = async () => {
  const res = await axios.get('/api/v1/time');

  return res.data.timestamp;
};

const getHighestValue = (pollData) => {
  if (!pollData) return null;
  const sortedOptions = pollData.options.sort(
    (a, b) => parseFloat(b.collected) - parseFloat(a.collected)
  );
  return sortedOptions[0];
}

export const getHighestPoll = (polls, id) => {
  const poll = polls.find(poll => poll.id === id);
  const results = getHighestValue(poll);
  return results;
}

export const filterDonationsByIndexRange = (donations, prize, minDonation) => {
  return donations.filter(donation => {
    return donation.index === prize && donation.amount >= minDonation;
  });
};

export const getUniqueDisplayNames = (donations) => {
  const uniqueNames = [];
  const uniqueDonations = [];

  donations.forEach(donation => {
    if (!uniqueNames.includes(donation.displayName)) {
      uniqueNames.push(donation.displayName);
      uniqueDonations.push(donation);
    }
  });

  return { uniqueNames, uniqueDonations };
};