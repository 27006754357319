import '../styles/globals.css';
import { observer } from 'mobx-react';
import Head from 'next/head';
import { configure } from 'mobx';
import 'normalize.css';
import 'css/global.css';
import 'css/dropdown.css';
import GlobalContextProvider from 'components/GlobalContextProvider';
import AuthListener from 'components/AuthListener';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import PropTypes from 'prop-types';

configure({ enforceActions: 'never' });

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = ({ Component, emotionCache, pageProps }) => (
  <AuthListener authRequired={Component.authRequired}>
    <CacheProvider value={emotionCache}>
      <GlobalContextProvider>
        <Head>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap"
          />
        </Head>
        <ThemeProvider theme={darkTheme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </GlobalContextProvider>
    </CacheProvider>
  </AuthListener>
);

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
  emotionCache: PropTypes.object,
};

App.defaultProps = {
  pageProps: {},
  emotionCache: muiCache,
};

export default observer(App);
