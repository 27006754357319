import { makeAutoObservable, action, flow, toJS } from 'mobx';
import axios from 'axios';

// With a combination of these two libraries, the same data will have
// the same checksum every time. Dict order in vanilla JS serialization does
// not guarantee any ordering. This provides us with something akin to a mutex
// across the transport, but still lets our transport layer be stateless.
import hash from 'object-hash';
import stringify from 'json-stable-stringify';

import { getTime, convertTime, getHighestPoll } from 'utils';

// Prevent recursive stringification
const filteredKeys = new Set([
  'rootStore',
  'lastReceivedHash',
  'updating',
  'plain',
  'stringified',
  'hash',
  'obs',
]);

const RTMPScenes = [
  '!RTMP SOURCES 01',
  '!RTMP SOURCES 02',
  '!RTMP SOURCES 03',
  '!RTMP SOURCES 04',
  '!RTMP SOURCES 05',
  '!RTMP SOURCES 06',
  '!RTMP SOURCES 07',
  '!RTMP SOURCES 08',
];

const RTMPSources = [
  'M - RTMP01',
  'M - RTMP02',
  'M - RTMP03',
  'M - RTMP04',
  'M - RTMP05',
  'M - RTMP06',
  'M - RTMP07',
  'M - RTMP08',
];

const bgOptions = [
  '!BG : ALinktothePast',
  '!BG : AxiomVerge',
  '!BG : GottaGoSlow',
  '!BG : MegaManMaker',
  '!BG : SuperMetroid',
  '!BG : TurboKid',
  '!BG : ChronoTrigger',
  '!BG : MarioRPG',
  '!BG : Kirby',
  '!BG : Synthwave',
];

class Store {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // Internal values
  lastReceivedHash = '';
  updating = false;
  campaign = {};
  hackTheStream = true;
  frameType = 'SuperMetroid';
  backgroundType = 'megaman';

  //OBSERVABLE VARIABLES
  timerOffset = { hours: 0, minutes: 0, seconds: 0 };
  lang = 'EN';
  currentGame = {
    title: 'Pre-Show',
    subtitle: '',
    category: 'Stream%',
    platform: 'Twitch',
    estimate: '00:50:00',
    extra: '',
  };
  nextGame = [
    {
      title: 'Chrono Trigger',
      subtitle: '',
      category: 'New PC Update',
      platform: 'PC',
      estimate: '04:20:69',
      extra: '',
    },
    {
      title: 'Next Game Name 2',
      subtitle: 'Next Game Subtitle 2',
      category: 'Next Game Category 2',
      platform: 'Next Game Platform 2',
      estimate: '04:20:69',
      extra: 'Extra Game Information 3',
    },
  ];
  gameName = 'Chrono Trigger';
  gameSubtitle = '';
  category = 'New PC Update';
  platform = 'PC';
  estimate = '04:20:69';
  estimate2 = '04:20:69';
  host = 'HostName';
  couch = 'CouchName';
  countdown = { minutes: 0 };
  cdtimer = { start: null, end: null };
  extraInformation = '';
  players = 1;
  runners = [
    {
      name: 'noresetspeedrun',
      //pronoun: 'they/them',
      endTime: null,
    },
    {
      name: 'New Runner',
      //pronoun: 'they/them',
      endTime: null,
    },
    {
      name: 'New Runner',
      //pronoun: 'they/them',
      endTime: null,
    },
    {
      name: 'New Runner',
      //pronoun: 'they/them',
      endTime: null,
    },
    {
      name: 'New Runner',
      //pronoun: 'they/them',
      endTime: null,
    },
    {
      name: 'New Runner',
      //pronoun: 'they/them',
      endTime: null,
    },
    {
      name: 'New Runner',
      //pronoun: 'they/them',
      endTime: null,
    },
    {
      name: 'New Runner',
      //pronoun: 'they/them',
      endTime: null,
    },
  ];
  runnerCount = 1;
  timer = { start: null, end: null };

  nextGameName = 'Chrono Trigger';
  nextCategory = 'New PC Update';
  nextPlatform = 'PC';
  nextRunner = 'Shokushu';
  nextGameName2 = 'Next Game';
  nextCategory2 = 'Next Category';
  nextPlatform2 = 'Next Platform';
  nextRunner2 = 'Next Runner';
  nextHost = 'Next Host';
  nextCouch = 'Next Couch';

  // HORARO
  horaroId = '1311w10fud57a57a64';
  horaroData = {
    items: [],
  };
  upcomingData = {
    items: [],
  };
  selectedRunIdx = 0;
  currentRunIdx = 0;
  rtmpURL = 'rtmp://rtmp.noresetspeed.run/stream/';

  showUpcomingRuns = false;

  test = {
    ip: 'obs.noreset.tv',
    port: '443',
    address: `obs.noreset.tv:443/obs`,
    connected: false,
  };

  donationCount = {
    currentTotal: 0.0,
    previousTotal: 0.0,
    lastDonation: 0.0,
  };

  obs = {
    socket: null,
    info: {},
    audio: [
      { name: 'Discord', muted: false },
      { name: 'Music', muted: false },
      { name: 'Mic', muted: false },
    ],
    rtmp: [
      { name: 'RTMP01', muted: false },
      { name: 'RTMP02', muted: true },
      { name: 'RTMP03', muted: true },
      { name: 'RTMP04', muted: true },
      { name: 'RTMP05', muted: false },
      { name: 'RTMP06', muted: true },
      { name: 'RTMP07', muted: true },
      { name: 'RTMP08', muted: true },
    ],
    selected: {
      current: 0,
      next: 0,
    },
    obsninja: [
      { obsname: '', name: 'GROUP A', muted: false, visible: true },
      { obsname: '', name: 'GROUP B', muted: true, visible: false },
      { obsname: '', name: 'HOST A', muted: true, visible: false },
      { obsname: '', name: 'HOST B', muted: true, visible: false },
    ],
    ad: [
      { name: 'AD 1', muted: false, visible: true },
      { name: 'AD 2', muted: true, visible: false },
      { name: 'AD 3', muted: true, visible: false },
      { name: 'AD 4', muted: true, visible: false },
      { name: 'AD 5', muted: true, visible: false },
    ],
    cam: [
      { name: 'Host', visible: true },
      { name: 'Wide', visible: true },
      { name: 'Runners', visible: true },
      { name: 'Mask', visible: true }, // ALWAYS LAST!!!!!!
    ],
  };

  fundky = {
    id: 1635, // 421
    pollid: 89, // 89 - 90 - 91
    frameid: 92, // 92 - 93 - 94
    amount: 0.0,
    background: '',
    polls: [],
    milestones: [],
    donations: [],
    prizes: [],
    donationStatus: {},
    donationsHidden: {},
    bidwarStatus: {},
    milestoneStatus: [],
    backgroundPoll: [],
    backgroundOptions: [],
    framePoll: [],
    frameOptions: [],
  };

  raffle = {
    names: [],
    spinning: false,
    winner: '',
    duration: 0,
    prize: 1,
    minDonation: 0,
  };

  setDuration = () => (this.raffle.duration = Math.floor(Math.random() * (15000 - 5000 + 1)) + 5000);

  setNames = names => (this.raffle.names = names);

  setPrize = prize => (this.raffle.prize = prize);

  setMinDonation = amount => (this.raffle.minDonation = amount);

  setWinner = name => (this.raffle.winner = name);

  setRaffleState = state => (this.raffle.spinning = state);

  getAdmin = async id => {
    try {
      let { data } = await axios.get(`/api/v1/host/${id}`);
      return data.data;
    } catch (error) {
      return false;
    }
  };

  setHack = state => (this.hackTheStream = state);

  setPollID = id => (this.fundky.pollid = id);

  setFrameID = id => (this.fundky.frameid = id);

  setFrameMan = name => (this.frameType = name);

  setBackgroundMan = name => {
    this.fundky.background = name;
    let test = `!BG : ${name}`;
    bgOptions.map(source => {
      this.obs.socket.send('SetSceneItemProperties', {
        'scene-name': '!BACKGROUNDS',
        item: source,
        visible: source === test,
      });
    });
  };

  setBackground = () => {
    let test = `!BG : ${this.fundky.background}`;
    bgOptions.map(source => {
      // console.log(`${source}`, source === test);
      this.obs.socket.send('SetSceneItemProperties', {
        'scene-name': '!BACKGROUNDS',
        item: source,
        visible: source === test,
      });
    });
  };

  startRecording = () => {
    this.obs.socket.send('StartRecording');
  };

  stopRecording = () => {
    this.obs.socket.send('StopRecording');
    setTimeout(() => this.obs.socket.send('StartRecording'), 5000);
  };

  tiltify = {
    id: '130283',
    amount: 0.0,
    polls: [],
    milestones: [],
    donations: [],
    donationStatus: {},
    donationsHidden: {},
    bidwarStatus: {},
    milestoneStatus: [],
  };

  adIndex = 0;

  setGroup = (isGroupB, idx) => {
    if (!isGroupB) this.obs.selected.current = idx;
    else this.obs.selected.next = idx;
  };

  SetRTMPVisibility = flow(function* (idxA, idxB) {
    let prev;
    let next;

    // GETS RTMP SOURCES FOR SWAP
    for (const source of RTMPSources) {
      const currentSource = yield this.obs.socket.send('GetSceneItemProperties', {
        'scene-name': RTMPScenes[idxA],
        item: source,
      });

      if (currentSource.visible) prev = currentSource.name;

      const swapSource = yield this.obs.socket.send('GetSceneItemProperties', {
        'scene-name': RTMPScenes[idxB],
        item: source,
      });

      if (swapSource.visible) next = swapSource.name;
    }

    // SETS REPLACEMENT RTMP SOURCES
    for (const source of RTMPSources) {
      this.obs.socket.send('SetSceneItemProperties', {
        'scene-name': RTMPScenes[idxA],
        item: source,
        visible: source === next,
      });

      this.obs.socket.send('SetSceneItemProperties', {
        'scene-name': RTMPScenes[idxB],
        item: source,
        visible: source === prev,
      });
    }
  });

  TempSwap = (idxA, idxB) => {
    [this.horaroData.items[idxA], this.horaroData.items[idxB]] = [
      this.horaroData.items[idxB],
      this.horaroData.items[idxA],
    ];
    const rtmpNames = Boolean(this.horaroData.items[this.selectedRunIdx].rtmp)
      ? this.horaroData.items[this.selectedRunIdx].rtmp.split(', ')
      : ['none', 'none', 'none', 'none'];
    const names = [rtmpNames[idxA], rtmpNames[idxB]];
    names.map((n, i) => {
      const sourceSettings = {
        buffering_mb: 1,
        clear_on_media_end: true,
        input: this.rtmpURL + n[i],
        input_format: 'rtmp',
        is_local_file: false,
        restart_on_activate: false,
      };
      let name = `RTMP0${i + 1}`;
      this.obs.socket.send('SetSourceSettings', {
        sourceName: name,
        sourceSettings: sourceSettings,
      });
    });
  };

  SwitchRTMP = (idxA, idxB) => {
    [this.runners[idxA], this.runners[idxB]] = [this.runners[idxB], this.runners[idxA]];
    [this.obs.rtmp[idxA], this.obs.rtmp[idxB]] = [this.obs.rtmp[idxB], this.obs.rtmp[idxA]];
    this.setRunnersT(idxA, this.runners[idxA].name);
    this.setRunnersT(idxB, this.runners[idxB].name);
    this.SetRTMPVisibility(idxA, idxB);
    // this.TempSwap(idxA, idxB);
  };

  setAdIndex = id => {
    this.adIndex = id;
    this.setAd(this.adIndex);
  };

  setAd = id => {
    this.obs.ad.map((data, index) => {
      data.muted = index !== id;
      data.visible = index !== id;
    });
    if (this.obs.ads !== undefined) {
      this.obs.ads.map((source, index) => {
        this.obs.socket.send('SetSceneItemProperties', {
          'scene-name': '!Ads',
          item: source,
          visible: index === id,
        });
      });
    }
  };

  setTiltifyID = id => (this.tiltify.id = id);
  setFundkyID = id => (this.fundky.id = id);
  setOBSIP = ip => (this.test.ip = ip);
  setOBSPort = port => (this.test.port = port);

  currentRTMP = 0;
  currentRTMP2 = 4;

  setTimerOffset = (name, number) => (this.timerOffset[name] = number);

  setRTMPAudio = index => {
    this.currentRTMP = index;
  };

  setRTMPAudioB = index => {
    this.currentRTMP2 = index;
  };

  setRunnerCount = playerList => {
    this.runnerCount = playerList.length;
  };

  toggleLanguage = () => {
    this.lang === 'EN' ? (this.lang = 'FR') : (this.lang = 'EN');
  };

  toggleUndo = () => {
    this.fundky.milestoneStatus.pop();
  };

  sendCurrent = () => {
    // NEW METHOD
    this.currentGame.title = this.horaroData.items[this.selectedRunIdx].game;
    this.currentGame.subtitle = Boolean(this.horaroData.items[this.selectedRunIdx].subtitle)
      ? this.horaroData.items[this.selectedRunIdx].subtitle
      : 'Game Subtitle';
    this.currentGame.category = this.horaroData.items[this.selectedRunIdx].category;
    this.currentGame.platform = this.horaroData.items[this.selectedRunIdx].platform;
    this.currentGame.extra = Boolean(this.horaroData.items[this.selectedRunIdx].extrainfo)
      ? this.horaroData.items[this.selectedRunIdx].extrainfo
      : '';
    this.currentGame.estimate = this.horaroData.items[this.selectedRunIdx].length_formated;
    this.nextGame.estimate =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].length_formated
        : '00:00:00';

    // OLD METHOD
    this.gameName = this.horaroData.items[this.selectedRunIdx].game;
    this.gameSubtitle = this.horaroData.items[this.selectedRunIdx].subtitle;
    this.category = this.horaroData.items[this.selectedRunIdx].category;
    this.platform = this.horaroData.items[this.selectedRunIdx].platform;
    this.extraInformation = Boolean(this.horaroData.items[this.selectedRunIdx].extrainfo)
      ? this.horaroData.items[this.selectedRunIdx].extrainfo
      : '';
    this.estimate = this.horaroData.items[this.selectedRunIdx].length_formated;
    this.estimate2 =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].length_formated
        : '00:00:00';

    this.host = this.horaroData.items[this.selectedRunIdx].host;
    this.couch = this.horaroData.items[this.selectedRunIdx].couch;
    // const pronouns = this.horaroData.items[this.selectedRunIdx].pronouns.split(', ');
    const runnerNames = this.horaroData.items[this.selectedRunIdx].runners.split(', ');
    this.setRunnerCount(runnerNames);
    // console.log('Runner Count: ', this.runnerCount);
    this.players = runnerNames.length;
    for (var i = 0; i < runnerNames.length; i++) {
      this.runners[i].name = runnerNames[i];
      //this.runners[i].pronoun = pronouns[i];
    }
    const rtmpNames = Boolean(this.horaroData.items[this.selectedRunIdx].rtmp)
      ? this.horaroData.items[this.selectedRunIdx].rtmp.split(', ')
      : ['none', 'none', 'none', 'none'];
    for (var j = 0; j < rtmpNames.length; j++) {
      const sourceSettings = {
        buffering_mb: 1,
        clear_on_media_end: true,
        input: this.rtmpURL + rtmpNames[j],
        input_format: 'rtmp',
        is_local_file: false,
        restart_on_activate: false,
      };
      let name = `RTMP0${j + 1}`;
      this.obs.socket.send('SetSourceSettings', {
        sourceName: name,
        sourceSettings: sourceSettings,
      });
    }
  };

  setRunnersT = (idx, name) => {
    switch (idx) {
      case 0:
        this.horaroData.items[this.selectedRunIdx].player01 = name;
        break;
      case 1:
        this.horaroData.items[this.selectedRunIdx].player02 = name;
        break;
      case 2:
        this.horaroData.items[this.selectedRunIdx].player03 = name;
        break;
      case 3:
        this.horaroData.items[this.selectedRunIdx].player04 = name;
        break;
      case 4:
        this.horaroData.items[this.selectedRunIdx].player05 = name;
        break;
      case 5:
        this.horaroData.items[this.selectedRunIdx].player06 = name;
        break;
      case 6:
        this.horaroData.items[this.selectedRunIdx].player07 = name;
        break;
      case 7:
        this.horaroData.items[this.selectedRunIdx].player08 = name;
        break;
    }
  };

  sendCurrentT = () => {
    this.currentGame.title = this.horaroData.items[this.selectedRunIdx].game;
    this.currentGame.category = this.horaroData.items[this.selectedRunIdx].category;
    this.currentGame.platform = this.horaroData.items[this.selectedRunIdx].platform;
    this.currentGame.extra = Boolean(this.horaroData.items[this.selectedRunIdx].extrainfo)
      ? this.horaroData.items[this.selectedRunIdx].extrainfo
      : '';
    this.currentGame.estimate = this.horaroData.items[this.selectedRunIdx].length_formated;

    this.estimate = this.horaroData.items[this.selectedRunIdx].length_formated;
    this.gameName = this.horaroData.items[this.selectedRunIdx].game;
    this.category = this.horaroData.items[this.selectedRunIdx].category;
    this.platform = this.horaroData.items[this.selectedRunIdx].platform;
    this.host = this.horaroData.items[this.selectedRunIdx].host;
    this.couch = this.horaroData.items[this.selectedRunIdx].couch;
    const runnerNames = this.horaroData.items[this.selectedRunIdx].runners.split(', ');
    this.setRunnerCount(runnerNames);
    this.players = runnerNames.length;
    for (var i = 0; i < runnerNames.length; i++) {
      this.runners[i].name = runnerNames[i];
    }
    this.extraInformation = Boolean(this.horaroData.items[this.selectedRunIdx].extrainfo)
      ? this.horaroData.items[this.selectedRunIdx].extrainfo
      : '';
    const rtmpNames = Boolean(this.horaroData.items[this.selectedRunIdx].rtmp)
      ? this.horaroData.items[this.selectedRunIdx].rtmp.split(', ')
      : ['none', 'none', 'none', 'none', 'none', 'none', 'none', 'none'];
    for (var j = 0; j < rtmpNames.length; j++) {
      const sourceSettings = {
        buffering_mb: 1,
        clear_on_media_end: true,
        input: this.rtmpURL + rtmpNames[j],
        input_format: 'rtmp',
        is_local_file: false,
        restart_on_activate: false,
      };
      let name = `RTMP0${j + 1}`;
      this.obs.socket.send('SetSourceSettings', {
        sourceName: name,
        sourceSettings: sourceSettings,
      });
    }
  };

  sendNext = () => {
    this.upcomingData.items = this.horaroData.items;
    this.currentRunIdx = this.selectedRunIdx;

    // NEW METHOD
    this.nextGame[0].title = this.horaroData.items[this.selectedRunIdx].game;
    this.nextGame[0].subtitle = this.horaroData.items[this.selectedRunIdx].subtitle;
    this.nextGame[0].category = this.horaroData.items[this.selectedRunIdx].category;
    this.nextGame[0].platform = this.horaroData.items[this.selectedRunIdx].platform;

    this.nextGame[1].title =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].game
        : 'Next Game 2';
    this.nextGame[1].subtitle =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].subtitle
        : 'Next Subtitle 2';
    this.nextGame[1].category =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].category
        : 'Next Category 2';
    this.nextGame[1].platform =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].platform
        : 'Next Platform 2';

    // OLD METHOD
    this.nextGameName = this.horaroData.items[this.selectedRunIdx].game;
    this.nextGameSubtitle = this.horaroData.items[this.selectedRunIdx].subtitle;
    this.nextCategory = this.horaroData.items[this.selectedRunIdx].category;
    this.nextPlatform = this.horaroData.items[this.selectedRunIdx].platform;
    //prevent an issue if the last run of the schedule is selected
    this.nextGameName2 =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].game
        : 'Next Game';
    this.nextCategory2 =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].category
        : 'Next Category';
    this.nextPlatform2 =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].platform
        : 'Next Platform';

    this.nextHost = this.horaroData.items[this.selectedRunIdx].host;
    this.nextCouch = this.horaroData.items[this.selectedRunIdx].couch;

    this.nextRunner = this.horaroData.items[this.selectedRunIdx].runners;
    this.nextRunner2 =
      this.horaroData.items.length - 1 > this.selectedRunIdx
        ? this.horaroData.items[this.selectedRunIdx + 1].runners
        : 'Next Runner';
  };

  sendNextT = () => {
    this.nextGameName = this.horaroData.items[this.selectedRunIdx].game;
    this.nextCategory = this.horaroData.items[this.selectedRunIdx].category;
    this.nextPlatform = this.horaroData.items[this.selectedRunIdx].platform;
    this.nextHost = this.horaroData.items[this.selectedRunIdx].host;
    this.nextCouch = this.horaroData.items[this.selectedRunIdx].couch;
    this.nextRunner = this.horaroData.items[this.selectedRunIdx].runners;
  };

  getPrev = () => {
    this.selectedRunIdx =
      this.selectedRunIdx == 0 ? this.horaroData.items.length - 1 : this.selectedRunIdx - 1;
    this.horaroData.items[this.selectedRunIdx];
  };

  getNext = () => {
    this.selectedRunIdx =
      this.selectedRunIdx == this.horaroData.items.length - 1 ? 0 : this.selectedRunIdx + 1;
    this.horaroData.items[this.selectedRunIdx];
  };

  startTimer = flow(function* () {
    const timestamp = yield getTime();
    let hoursInMilliseconds = this.timerOffset.hours * 60 * 60 * 1000;
    let minutesInMilliseconds = this.timerOffset.minutes * 60 * 1000;
    let secondsInMilliseconds = this.timerOffset.seconds * 1000;
    let offset = hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds;

    // console.log({ hoursInMilliseconds });
    // console.log({ minutesInMilliseconds });
    // console.log({ secondsInMilliseconds });
    // console.log({ offset });

    this.timer.start = this.timer.start || timestamp - offset;
    this.timer.end = null;
    // console.log('Timer Started', this.timer.start, this.timer.end);
  });

  continueTimer = () => {
    this.timer.end = null;
    // console.log('Timer Continued', this.timer.end);
  };

  startCountdown = flow(function* () {
    const timestamp = yield getTime();
    this.cdtimer.start = this.cdtimer.start || timestamp;
    this.cdtimer.end = timestamp + this.countdown.minutes * 60000;
  });

  resetCountdown = () => {
    this.cdtimer.start = null;
    this.cdtimer.end = null;
  };

  getItems = () => {
    axios
      .get(`/api/v1/schedule/${this.horaroId}`)
      .then(res => {
        const { data } = res;

        data.items = data.items.map(item =>
          item.data.reduce(
            (acc, curr, idx) => {
              acc[data.columns[idx].toLowerCase()] = curr;
              return acc;
            },
            {
              length: item.length,
              length_t: item.length_t,
              scheduled: item.scheduled,
              scheduled_t: item.scheduled_t,
              length_formated: convertTime(item.length_t),
            },
          ),
        );

        this.horaroData = { items: [], ...data };
        const runnerNames = this.horaroData.items[0].runners.split(', ');
        for (var i = 0; i < runnerNames.length; i++) {
          this.setRunnersT(i, runnerNames[i]);
        }

        // console.log('Horaro Data:', toJS(this.horaroData));
      })
      .catch(() => {
        this.horaroData = { items: [] };
      });
  };

  getPolls = async () => {
    try {
      const response = await axios.get(`/api/v1/polls/${this.fundky.id}`);
      if ([200, 201, 304].includes(response.status)) {
        const polls = response.data.polls || [];
        this.fundky.polls = polls;
        let count = toJS(polls);
        if (this.obs?.socket?._connected && this.hackTheStream && count.length > 0) {
          const bgPoll = getHighestPoll(toJS(polls), parseInt(this.fundky.pollid));
          this.fundky.background = bgPoll?.description.description.replace(/\s/g, '');
          this.setBackground();
          const fPoll = getHighestPoll(toJS(polls), parseInt(this.fundky.frameid));
          this.frameType = fPoll?.description.description.replace(/\s/g, '');
        }
      }
    } catch (error) {
      this.fundky.polls = [];
      console.error("ERROR COULD'T FETCH POLLS", error.message);
    }
  };

  getTotalRaised = async () => {
    try {
      let response = await axios.get(`/api/v1/donations/total/${this.fundky.id}`);
      if (response.status == 200) {
        // console.log('TOTAL RAISED --- ', toJS(response.data.current));
        this.fundky.amount = response.data.current;
        if (this.donationCount.currentTotal !== this.fundky.amount) {
          this.donationCount.lastDonation = this.fundky.amount - this.donationCount.currentTotal;
          this.donationCount.previousTotal = this.donationCount.currentTotal;
          this.donationCount.currentTotal = this.fundky.amount;
        }
      }
    } catch (error) {
      this.fundky.amount = 0.0;
      // console.log("ERROR COULD'T FETCH TOTAL RAISED", error.message);
    }
  };

  getMilestones = async () => {
    try {
      let response = await axios.get(`/api/v1/milestones/${this.fundky.id}`);
      if (response.status == 200) {
        // console.log('MILESTONES FETCHED --- ', toJS(response.data.milestones));
        this.fundky.milestones = response.data.milestones; //.filter(m => m.amount > this.fundky.amount);
      }
    } catch (error) {
      this.fundky.milestones = [];
      console.error("ERROR COULD'T FETCH MILESTONES --- ", error.message);
    }
  };

  getDonationList = async () => {
    try {
      let response = await axios.get(`/api/v1/donations/list/${this.fundky.id}`);
      if (response.status === 200) {
        // console.log('DONATION LIST FETCHED --- ', toJS(response.data.donations));
        this.fundky.donations = response.data.donations;
        if (response.data.donations.length > 0) {
          response.data.donations.filter(item => item.anonymous !== 1).map(donation => {
            const exists = this.fundky.prizes.some(newDonation => newDonation.id === donation.id);
            if (exists) {
              return;
            }
            const newDonation = {
              id: donation.id,
              displayName: donation.displayName,
              amount: donation.amount,
              gameName: this.currentGame.title,
              index: this.raffle.prize,
              created_at: new Date().toISOString(),
            };
            this.fundky.prizes.push(newDonation);
          });
        }
      }
    } catch (error) {
      this.fundky.donations = [];
      // console.log("ERROR COULD'T FETCH DONATION LIST", error.message);
    }
  };

  stopTimer = flow(function* () {
    if (!this.timerStarted) {
      return;
    }

    const timestamp = yield getTime();

    this.timer = { ...this.timer, end: timestamp };
    // console.log('Timer Ended', this.timer.start, this.timer.end);
  });

  resetTimer = () => {
    for (const r of this.runners) {
      r.endTime = null;
    }
    this.timer = { start: null, end: null };
    this.timerOffset = { hours: 0, minutes: 0, seconds: 0 };
  };

  get upcomingRuns() {
    const res = [];
    if (this.upcomingData.items === undefined) {
      return '';
    }
    for (const horaroEntry of this.upcomingData.items.slice(
      this.currentRunIdx,
      this.currentRunIdx + 4,
    )) {
      const { game, category, platform, runners, pollid, length_formated, milestoneid } =
        horaroEntry;

      res.push({ game, category, platform, runners, pollid, length_formated, milestoneid });
    }
    return res;
  }

  get cdtimerStarted() {
    return Boolean(this.cdtimer.start);
  }

  get cdtimerCompleted() {
    return Boolean(this.cdtimer.start) && Boolean(this.cdtimer.end);
  }

  get timerStarted() {
    return Boolean(this.timer.start);
  }

  get timerCompleted() {
    return Boolean(this.timer.start) && Boolean(this.timer.end);
  }

  get jsonString() {
    return JSON.stringify(toJS(this));
  }

  get currentlySelectedRun() {
    // Check for null explicitly because 0 is falsy
    if (this.selectedRunIdx === null || !this.horaroData.items) {
      return null;
    }
    return this.horaroData.items[this.selectedRunIdx];
  }

  get plain() {
    return toJS(this);
  }

  get stringified() {
    return stringify(this.plain, {
      replacer: (k, v) => {
        if (filteredKeys.has(k)) {
          // Remove it from the output
          return undefined;
        }

        return v;
      },
    });
  }

  get hash() {
    return hash(this.stringified);
  }
}

export default Store;
