import { useState, useEffect, useContext } from 'react';
import { reaction } from 'mobx';
import { useObserver } from 'mobx-react';
import GlobalContext from 'contexts';

import { getTime } from 'utils';

export const useGlobalContext = () => useObserver(() => useContext(GlobalContext));

export const useCurrentTime = () => {
  const [currTime, setCurrTime] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      getTime().then(t => setCurrTime(t));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return currTime;
};

export const useTiltifyUpdates = () => {
  const store = useGlobalContext();

  useEffect(() => {
    const intervalId = setInterval(() => {
      store.getMilestones();
      store.getPolls();
      store.getTotalRaised();
      store.getDonationList();
    }, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(
    () =>
      reaction(
        () => store.fundky.id,
        () => {
          store.getMilestones();
          store.getPolls();
          store.getTotalRaised();
          store.getDonationList();
        },
      ),
    [],
  );
};
